import * as React from "react"
import { graphql } from "gatsby"

import { Box, 
  Container,
} from "@chakra-ui/react"

  import Seo from "../components/seo"
  import BackgroundImage from "../components/Backgrounds/BackgroundImage"
  import SidebarNav from "../components/SidebarNav/SidebarNav"
  import MainContentBox from "../components/MainContentBox/MainContentBox"
  import MobileNav from "../components/MobileNav/MobileNav"
  import Footer from "../components/Footer/Footer"
  import PageHero  from "../components/PageHero/PageHero"
  import SliceZone from "../components/SliceZone/SliceZone"
  import { ChevronRightIcon } from "@chakra-ui/icons"
  import { Breadcrumb } from "gatsby-plugin-breadcrumb"
  import { getImage } from "gatsby-plugin-image"

  const TetonValleyDevelopmentMap = ({ pageContext, location, data }) => {

    const {
      breadcrumb: { crumbs },
    } = pageContext

    const pageLocation = location.pathname

    if (!data) return null
    const document = data.allPrismicPage.edges[0].node.data
    const backgroundImage = data.allPrismicPage.edges[0].node.data.background_image.localFile
  
    const heroContent = {
      title: document.page_title.text,
      description: document.page_description.text,
      ctaButtons: document.cta_buttons
    }

    const customCrumbLabel = heroContent.title

    return (
      <>
      <Seo title="Teton Valley Advocates | Teton Valley Development Map" />
      <BackgroundImage
        backgroundImage={getImage(backgroundImage)}
      >
        <Container maxW="container.xl">
          <MobileNav />
          <Box paddingTop="20" width="100%">
              <SidebarNav 
                pathName={pageLocation}
              />
          <MainContentBox>
             <PageHero 
              title={heroContent.title}
              description={heroContent.description}
              buttons={heroContent?.ctaButtons}
              location={location}
              crumbLabel={heroContent.title}
            />
            <Box p="4" backgroundColor="rgba(255, 255, 255, 0.8)" textAlign="center">
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator={<ChevronRightIcon />}
              crumbLabel={customCrumbLabel}
          />
          </Box>
          <Box>
          <iframe src="https://www.google.com/maps/d/embed?mid=1GoqsNjBNY0swX3bp-2YblYHu9ZxLmxlm" width="100%" height="800"></iframe>
          </Box>
          <Box p="4" backgroundColor="rgba(255, 255, 255, 0.8)">
            <SliceZone sliceZone={document.body} />
          </Box>
          {/* <BlogPostsBlock title="Facebook Block..." />       */}
            <Footer />
          </MainContentBox>
          </Box>

        </Container>

    </BackgroundImage>
      </>
    )
  }

  export const query = graphql`
  query DevelopmentMap {
    allPrismicPage(filter: {uid: {eq: "teton-valley-development-map"}}) {
      edges {
        node {
          data {
            page_title {
              text
            }
            page_description {
              text
            }
            featured_images {
              featured_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO)
                  }
                }
                alt
              }
            }
            cta_buttons {
              button_color
              cta_button_link {
                uid
              }
              cta_button_label {
                text
              }
            }
            body {
              ... on PrismicPageDataBodyStaffBoardMember {
                id
                slice_type
                items {
                  content {
                    raw
                  }
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, aspectRatio: 1,
                          transformOptions: {cropFocus: CENTER})
                      }
                    }
                  }
                  name {
                    text
                  }
                  title {
                    text
                  }
                }
                primary {
                  title {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyBlogPostContent {
                id
                items {
                  post_content {
                    raw
                  }
                }
                slice_type
              }
              ... on PrismicPageDataBodyOurWorkCards {
            id
            items {
              featured_project
              brief_project_description {
                text
              }
              project_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, aspectRatio: 1.5)
                  }
                }
                alt
              }
              project_title {
                text
              }
              project_link {
                uid
                url
              }
            }
            slice_type
          }
              ... on PrismicPageDataBodyCallToAction {
                id
                items {
                  button_color
                  button_label
                  button_link {
                    uid
                    url
                  }
                }
                slice_type
                primary {
                  title {
                    text
                    raw
                  }
                  paragraph {
                    text
                    raw
                  }
                  background_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
              ... on PrismicPageDataBodyNewsletterSignUp {
                id
                slice_type
                primary {
                  button_color
                  button_text {
                    text
                  }
                  paragraph {
                    text
                  }
                  title {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyOurWorkTabs {
                id
                slice_type
                items {
                  tab_content {
                    raw
                  }
                  tab_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: FULL_WIDTH
                          formats: AUTO
                          placeholder: BLURRED
                          quality: 60
                        )
                      }
                    }
                    alt
                  }
                  tab_subtitle {
                    text
                  }
                  tab_title {
                    text
                  }
                }
                primary {
                  tab_section_subtitle {
                    text
                  }
                  tab_section_title {
                    text
                  }
                }
              }
            }
            background_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, formats: AUTO, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
  
`

  export default TetonValleyDevelopmentMap
